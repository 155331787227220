<template>
  <form @submit.prevent="onsubmit()">
    <va-input
      v-model.trim="license_key"
      type="text"
      label="License Key"
      placeholder="Enter License Key"
      :error="!!licenseKeyErrors.length"
      :error-messages="licenseKeyErrors"
    />
    <label v-if="isShowError" style="color: #f8706d;">{{err_message}}</label>
    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">Submit</va-button>
    </div>
    <va-modal
      v-model="showModal"
      title="User Detail"
      okText="Ok"
      @ok="okModal()"
      cancelText=''
      noOutsideDismiss
      noEscDismiss
    >
      <div><label>User Name : {{user_name}}</label></div>
      <div><label>Password  : {{password}}</label></div>
    </va-modal>
  </form>
</template>

<script>
import config from '../../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
export default {
  name: 'signup',
  data () {
    return {
      license_key: '',
      licenseKeyErrors: [],
      isShowError: false,
      showModal: false,
      user_name: '',
      password: '',
      err_message: '',
    }
  },
  methods: {
    onsubmit () {
      this.isShowError = false
      this.showModal = false
      this.licenseKeyErrors = this.license_key ? [] : ['License Key is required']
      if (!this.formReady) {
        return
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'auth/validate_license_key', { license_key: this.license_key }).then(response => {
        loader.hide()
        var responseBody = response.body
        this.showModal = true
        this.user_name = responseBody.user_name
        this.password = responseBody.password
      }, error => {
        loader.hide()
        this.isShowError = true
        if (error.body) {
          this.err_message = error.body
        } else {
          this.err_message = 'Invalid Licence Key Found'
        }
      })
    },
    okModal () {
      this.$router.push({ name: 'login' })
    },
  },
  computed: {
    formReady () {
      return !(this.licenseKeyErrors.length)
    },
  },
}
</script>

<style lang="scss">
</style>
